import React from 'react';

import { useFeatureFlags, useRedirectToAccountIfUser } from 'Utilities/hooks';

import { ComponentsWrapper } from 'Layout';

import RegistrationPage from '../authentication/Pages/RegistrationPage/RegistrationPage';
import { SignIn } from '../fastlane/signIn/SignIn';

const SignInContainer = () => {
  useRedirectToAccountIfUser();

  const { showCognitoLogin } = useFeatureFlags();

  if (showCognitoLogin) {
    return (
      <RegistrationPage isEmbedded={false} />
    );
  }

  return (
    <ComponentsWrapper>
      <SignIn />
    </ComponentsWrapper>
  );
};

export default SignInContainer;
